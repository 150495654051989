.posts {
    
    display: flex;
    flex-direction: column;
    padding: 0 0.8rem;
    gap: 0.5rem;

  .info-post {
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    border: 1.5px solid $color-accent-light;
    background-color: $color-grey-light;
    
    
    .info-post__header {
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $color-main-dark;
      width: 100%;
      margin: 0;
      padding: 0.6rem;
      box-sizing: border-box;
      border-bottom: 1.5px dashed $color-accent-light;
    }
    img{
      display: block;
      margin:auto;
      max-width:100%;
    }

    .info-post__content {
      padding: 5px 15px;
      margin: 0.6rem;
    }
  }
  @media (min-width: 640px) {
    .info-post{
      border-radius: 0px;
    }

  }
  @media (min-width: 1024px) {
  }

  //temp classes
  .info-post__temp-list-title{
    padding-right: 10px;
    font-size: 16px;
    ul{
      padding-inline-start: 20px;
      
    }
    li{
      list-style-type:none;
      padding-bottom: 6px;
    }
  }
}
