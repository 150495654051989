.embeded-map {
  overflow: hidden;
  display: block;
  max-height: 200px;
  //max-width: "100%";
  background-color: lightgrey;
  padding: 11px 2px 8px 2px;

  iframe {
    border: none;
  }

  #gmp-map {
    left: 0;
    bottom: 50%;
  }

  #locations-panel {
    display: none;
  }
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.map-container a {
  text-decoration: none;
  color: #1967d2;
}

.map-container button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-size: inherit;
  cursor: pointer;
}

#gmp-map {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#locations-panel {
  display: none;
}

@media (min-width: 640px) {
  
}
