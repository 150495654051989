.image-slideshow {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  .image-slideshow__image {
    width: 100%;
  }

  .image-slideshow__nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -3.6rem;

    button {
      height: fit-content;
      border: none;
      background: none;

      svg {
        height: 4rem;
        width: fit-content;
        fill: $color-main-light;
        stroke: $color-grey-medium;
        stroke-width: 0.2px;
      }

      &.previous-img {
        margin-left: -1rem;
      }
      &.next-img {
        margin-right: -1rem;
      }
    }
  }

  @media (min-width: 1000px) {
    /*.image-slideshow__image {
      
      definer størrelses-constraints senere
      max-height: 380px;
      width: auto;
    }
    */
    .image-slideshow__nav {
      margin-top: 0;
      max-width: 26rem;
    }
  }
}
