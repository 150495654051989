.App {
  height: 100vh;
  width: 100%;
  
  

  .contained-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  link {
    color: $color-accent-dark;
  }
  .main-content {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .main-content-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .desktop-only{
    display: none;
  } 

  .centered{
    text-align: center;
    
  }


  @media (min-width: 640px) {
    .main-content {
      margin: 0 auto;
      max-width: 1024px;
      flex-direction: row;
    }
    .main-content-inner {
      flex: 3;
      order: 2;
    }
    .desktop-only{
      display: block;
  } 
  }
  @media (min-width: 1024px) {
    .main-content{
      max-width: 1024px;
    }
  }

// for ultra wide screens
 // @media (min-width: 2500px) {
   // .main-content{
     //max-width: 1324px;
   // }
//}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
