.artist-reel {
  max-height: 175px;
  height: fit-content;
  padding: 7px 0px 2px 0px;
  background-color: $color-grey-light;
  max-width: 100%;
  overflow: hidden;
  

  .artist-reel__header {
    width: 100%;
    margin: 0;
    font-family: $font-serif;
    font-size: 1.4rem;
    text-align: center;
    color: $color-accent-medium;
  }
  .artist-reel__artists {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 25px;
    padding: 0.6rem 20px 0 10px;
  }
  .artist-reel__artists::-webkit-scrollbar {
    display: none;
  }

  .artist-card {
    min-width: 20vw;
    max-height: 125px;
    margin: 0 0.6rem;
    overflow-wrap:break-word;
    text-align: center;

    img {
      height: 80px;
      width: 88px;
      object-fit:cover;
    }

    .artist-name {
      font-size: 13px;
      font-weight: bold;
      max-height: 35px;
      overflow:hidden;
      padding-bottom: 5px;
      
    }

    .bio {
      display: none;
    }
    a,
    a:visited,
    a:focus,
    a:hover {
      color: $color-main-dark;
      text-decoration: none;
      cursor: pointer;
    }
  }


  
  //For tablets and larger screens
  @media (min-width: 640px) {
    flex: 1;
    order: 1;
    max-height: 100%;
    width: 300px;
    margin-left: 10px;
    overflow: unset;
    

    .artist-reel__artists {
      padding: 0.6rem 0;
      display: flex;
      //flex-direction: column;
    }
    .artist-card {
      height:250px;
      min-width: 0;
      padding-bottom: 90px;
      .bio {
        display: block;
        padding-bottom: 10px;
      }
      .artist-img {
        width: 220px;
        height: 190px;
        
      }
    }

    .artist-name {
      font-size: 16px !important;
      padding-bottom: 10px;      
    }

    //temp classes
    
    .sponsors-section{
      display:block;
    }
  }

  //For Desktop Monitors and larger
  @media (min-width: 1024px) {
  }
}
