@import "../variables";
//Utils

//Styles
@import "./App", "../layout/ArtistReel", "../layout/MainHeader",
"../layout/ImageSlideshow", "../layout/Posts", "../layout/Video",
"../layout/EmbededMap", "../layout/SponsorCard","../layout/Card", "../layout/MainFooter";


  

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-teal-grey-light;
}
.marked-text {
  background-color: yellow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 640px) {
}

@media (min-width: 1024px) {
}
