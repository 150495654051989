.main-header {
  /* background-color: #33708f;
    background-repeat: no-repeat; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #d54000;
  min-height: 50px;
  padding: 0.8rem;
  max-width: 100%;
  .logo-link{
    display: block;
    max-width: 50%;  
    height:auto;  
  }
  nav {
    padding-bottom: 7px;
    gap: 0.8em;
    display: flex;
    align-items: flex-end;
    a, //de fleste statuser for a vil ha denne stylingen. fjernet a:active, men må kanskje ekskludere flere?
        a:link,
        a:visited,
        a:focus,
        a:hover {
      color: #d54000;
      text-decoration: none;
      cursor:pointer;
      max-height: 30px;
    }
  }
  .nav-icon{
    max-height: 30px;
    aspect-ratio: 1/1;
  }
  .logo {
    height: 100%;
    max-width: 100%;
    object-fit: scale-down;
    
  }
  // Mobile landscape, tablet
  @media (min-width: 640px) {
    .logo-link{
      
      max-width: 30%;  
       
    }
    .logo {
      max-width: 100%;
      height:auto;
    }
    nav{
      padding-bottom: 5px;
    }

  }
  // Desktop
  @media (min-width: 1024px) {
    margin: auto;
    padding-right: 1.5vw;
    max-width: $desktop-width;
    nav{
      padding-bottom: 3px;
    }

  }
}
