.card{
    text-align: center;
    width: 100%;
    border-radius: 10px;
    border: solid #457b87;
    padding: 5px 10px;
    

/*     h1{
        color: #457b87;
    } */
    
}